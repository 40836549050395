<template>
  <Teleport to="body">
    <div
      aria-live="assertive"
      class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 mt-12 z-20"
    >
      <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
        <Toast
          v-for="toast in toasts"
          :key="toast.id"
          :toast="toast"
          @remove-toast="removeToast"
        />
      </div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import {
  type ToastContainerInterface,
  type ToastInterface,
} from '@ambassy/innercrowd-ui';
import { Toast } from '@ambassy/innercrowd-ui';

const toasts = ref<ToastInterface[]>([]);

const addToast = (toast: Omit<ToastInterface, 'id'>) => {
  const newToast: ToastInterface = {
    ...toast,
    id: toasts.value.length,
  };
  toasts.value?.push(newToast);
  setTimeout(() => {
    removeToast(newToast.id);
  }, newToast.duration);
};

const removeToast = (toastId: number) => {
  toasts.value = toasts.value.filter((toast) => toast.id !== toastId);
};

const removeAllToasts = () => {
  toasts.value = [];
};

defineExpose<ToastContainerInterface>({
  addToast,
  removeToast,
  removeAllToasts,
});
</script>
