import { defineStore } from 'pinia';
import {
  AmbassadorRewardProductResponse,
  AmbassadorRewardProductStockResponse,
} from '@ambassy/schema';

export const useRewardProductStore = defineStore(
  'detail-card-rewardProductStore',
  {
    state: () => ({
      reward: {} as AmbassadorRewardProductResponse,
      stock: {} as AmbassadorRewardProductStockResponse,
      isLoading: true,
      errorMessage: '',
    }),
    actions: {
      startLoading() {
        this.isLoading = true;
      },
      stopLoading() {
        this.isLoading = false;
      },
      clearErrorMessage() {
        this.errorMessage = '';
      },
      setReward(reward: AmbassadorRewardProductResponse) {
        this.reward = reward;
      },
      setStock(stock: AmbassadorRewardProductStockResponse) {
        this.stock = stock;
      },
      setErrorMessage(errorMessage: string) {
        this.errorMessage = errorMessage;
      },
    },
  },
);
