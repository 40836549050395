import { NavigationGuard } from 'vue-router';

let isRouteChangeBlocked: boolean = false;

export function blockRouteChange(): void {
  isRouteChangeBlocked = true;
}

export function unblockRouteChange(): void {
  isRouteChangeBlocked = false;
}

export const checkConfirmation: NavigationGuard = (to, from, next) => {
  if (isRouteChangeBlocked) {
    if (confirm('There are unsaved changes, do you want to continue?')) {
      unblockRouteChange();
      next();
    }
    return;
  }
  next();
};
