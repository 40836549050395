import { defineStore } from 'pinia';
import { ClientErrorInterface, FieldsResponse } from '@ambassy/schema';

export const useFieldsModalFieldStore = defineStore('organisms-fieldsStore', {
  state: () => ({
    fields: [] as FieldsResponse,
    isLoading: true,
    error: '',
  }),
  actions: {
    isEmpty() {
      return this.fields?.length === 0;
    },
    setFields(fields: FieldsResponse) {
      this.fields = fields;
    },
    setError(error: ClientErrorInterface<any>) {
      this.error = error.message;
    },
    startLoading() {
      this.isLoading = true;
    },
    stopLoading() {
      this.isLoading = false;
    },
    findById(id: string) {
      return this.fields.find((field) => field.id === id);
    },
    clearError() {
      this.error = '';
    },
  },
});
