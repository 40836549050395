<template>
  <button
    :disabled="disabled"
    @click="click"
    :class="[
      disabled ? 'opacity-50 bg-gray-300 cursor-not-allowed' : '',
      isLoading ? 'pl-6' : 'px-6',
      'flex justify-center items-center rounded-md border border-transparent bg-indigo-600 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
    ]"
  >
    <slot />
    <span v-if="isLoading" class="ml-2">
      <Spinner class="h-4 w-4 text-white" />
    </span>
  </button>
</template>

<script lang="ts" setup>
import Spinner from '../../atoms/Loaders/Spinner.vue';

const props = defineProps<{
  disabled?: boolean;
  isLoading?: boolean;
}>();
const emits = defineEmits<{
  (events: 'primary-button-clicked'): void;
}>();

const click = () => {
  if (props.disabled) return;
  emits('primary-button-clicked');
};
</script>
