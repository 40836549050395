import { defineStore } from 'pinia';
import {
  AmbassadorRewardsResponse,
  ClientErrorInterface,
} from '@ambassy/schema';

export const useRewardClaimRewardStore = defineStore('rewardClaimRewardStore', {
  state: () => ({
    isLoading: true,
    errorMessage: '',
    reward: {} as AmbassadorRewardsResponse[number],
  }),
  actions: {
    startLoading() {
      this.isLoading = true;
    },
    stopLoading() {
      this.isLoading = false;
    },
    clearError() {
      this.errorMessage = '';
    },
    setError(err: ClientErrorInterface<any>) {
      this.errorMessage = err.message;
    },
    setReward(reward: AmbassadorRewardsResponse[number]) {
      this.reward = reward;
    },
  },
});
