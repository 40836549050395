import { defineStore } from 'pinia';

export const usePointsStore = defineStore('points-card-points', {
  state: () => ({
    points: 0,
    isLoading: true,
    errorMessage: null as string | null,
  }),
  actions: {
    setPoints(points: number) {
      this.points = points;
    },
    startLoading() {
      this.isLoading = true;
    },
    stopLoading() {
      this.isLoading = false;
    },
  },
});
