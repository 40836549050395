<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import { ExclamationCircleIcon } from '@heroicons/vue/20/solid';

const props = withDefaults(
  defineProps<{
    modelValue?: string | null;
    iconPosition?: string;
    type?: string;
    required?: boolean;
    autofocus?: boolean;
    autocomplete?: string;
    placeholder?: string;
    error?: string | string[];
    disabled?: boolean;
    maxlength?: number;
  }>(),
  {
    iconPosition: 'start',
    type: 'text',
    autofocus: false,
  },
);

defineEmits<{
  (event: 'update:modelValue', ...args: any[]): void;
}>();

const input = ref<HTMLInputElement | null>(null);

onMounted(() => {
  if (props.autofocus && input.value) {
    input.value.focus();
  }
});
</script>

<template>
  <div class="relative flex rounded-md shadow-sm">
    <input
      :class="[
        error
          ? 'border-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500'
          : '',
        disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer',
        'block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm leading-5 font-normal disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200',
      ]"
      :disabled="disabled"
      :value="modelValue"
      :type="type"
      :required="required"
      :autocomplete="autocomplete"
      :placeholder="placeholder"
      :maxlength="maxlength"
      @input="
        $emit('update:modelValue', ($event.target as HTMLInputElement)?.value)
      "
      ref="input"
    />
    <p
      v-if="maxlength"
      class="absolute inset-y-0 right-0 flex items-center pr-3 text-sm text-gray-400"
    >
      {{ modelValue?.length ?? 0 }} / {{ maxlength }}
    </p>
    <div
      v-if="error"
      class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
    >
      <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
    </div>
  </div>
</template>
