import { defineStore } from 'pinia';
import { LandingPageQuestionFromResponse } from '@ambassy/schema';
export const useLandingPageStore = defineStore('landing-page', {
  state: () => ({
    landingPage: null as LandingPageQuestionFromResponse | null,
    isLoading: true,
    errorMessage: '',
  }),
  actions: {
    setLandingPage(landingPage: LandingPageQuestionFromResponse) {
      this.landingPage = landingPage;
    },
    stopLoading() {
      this.isLoading = false;
    },
    setError(error: Error) {
      this.errorMessage = error.message;
    },
  },
});
