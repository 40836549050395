import { defineStore } from 'pinia';
import {
  AmbassadorQuestsResponse,
  AmbassadorTicketQuestPerformanceResponse,
  AmbassadorTicketQuestShareLinkResponse,
  ClientErrorInterface,
  QuestDiscountsResponse,
  RewardsForQuestResponse,
} from '@ambassy/schema';

type GroupedTickets = { letter: string; amount: number; soldAt: Date | null }[];
const groupTickets = (
  tickets: AmbassadorTicketQuestPerformanceResponse['tickets'],
): GroupedTickets => {
  if (!tickets) return {} as GroupedTickets;
  const grouped = tickets.reduce(
    (acc, ticket) => {
      const key = ticket.orderId;
      if (!acc[key]) {
        acc[key] = {
          letter: ticket.letter,
          amount: 0,
          soldAt: ticket.soldAt ? new Date(ticket.soldAt) : null,
        };
      }
      acc[key].amount += 1;
      return acc;
    },
    {} as Record<string, GroupedTickets[number]>,
  );
  let values = Object.values(grouped);
  values = values.sort(
    (a, b) => (b.soldAt?.getTime() ?? 0) - (a.soldAt?.getTime() ?? 0),
  );
  return values;
};

export const useTicketSellQuestsStore = defineStore('ticket-sell-quests', {
  state: () => ({
    quests: {} as AmbassadorQuestsResponse,
    selectedQuest: null as AmbassadorQuestsResponse['items'][number] | null,
    performance: {} as AmbassadorTicketQuestPerformanceResponse,
    tickets: {} as GroupedTickets,
    shareLink: {} as AmbassadorTicketQuestShareLinkResponse,
    discounts: {} as QuestDiscountsResponse,
    isLoading: true,
    errorMessage: '',
    points: [] as RewardsForQuestResponse,
  }),
  actions: {
    setQuests(quests: AmbassadorQuestsResponse) {
      this.quests = quests;
    },
    selectFirst() {
      this.selectedQuest = this.quests.items[0] ?? null;
    },
    startLoading() {
      this.isLoading = true;
    },
    stopLoading() {
      this.isLoading = false;
    },
    setError(err: ClientErrorInterface<any>) {
      this.errorMessage = err.message;
    },
    selectQuest(questId: string) {
      this.selectedQuest =
        this.quests.items.find((quest) => quest.id === questId) ?? null;
    },
    setPerformance(performance: AmbassadorTicketQuestPerformanceResponse) {
      this.performance = performance;
      this.tickets = groupTickets(performance.tickets);
    },
    setShareLink(shareLink: AmbassadorTicketQuestShareLinkResponse) {
      this.shareLink = shareLink;
    },
    setDiscount(discounts: QuestDiscountsResponse) {
      this.discounts = discounts;
    },
    setPoints(points: RewardsForQuestResponse) {
      this.points = points;
    },
  },
  getters: {
    getDiscount:
      (state) =>
      (questId: string): QuestDiscountsResponse[string] => {
        return state.discounts[questId];
      },
    getPoints:
      (state) =>
      (questId: string): RewardsForQuestResponse[number] | undefined => {
        return state.points.find((reward) => reward.questId === questId);
      },
  },
});
