import { ref } from 'vue';
import { HomepageTextRequest } from '@ambassy/schema';

const homeText = ref<HomepageTextRequest>();

export const setHomeText = (text: HomepageTextRequest) => {
  homeText.value = text;
};

export const useHomeText = () => {
  return homeText;
};
