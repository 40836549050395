import { defineStore } from 'pinia';
import { PanelType } from '../panels';

export const useGraphStore = defineStore('graph', {
  state: () => ({
    trigger: null as PanelType | null,
    condition: [] as PanelType[],
    action: null as PanelType[] | null,
  }),
  actions: {
    setTrigger(trigger: PanelType) {
      this.trigger = trigger;
    },
    addCondition(condition: PanelType) {
      this.condition.push(condition);
    },
    removeCondition(condition: PanelType) {
      this.condition = this.condition.filter((c) => c !== condition);
    },
    setAction(action: PanelType[]) {
      this.action = action;
    },
  },
  getters: {
    panels: (state): { actions: PanelType | PanelType[] }[] => {
      const panels = [];
      if (state.trigger) {
        panels.push({ actions: state.trigger });
      }
      if (state.condition.length) {
        panels.push(...state.condition.map((c) => ({ actions: c })));
      }
      if (state.action) {
        panels.push({ actions: state.action });
      }
      return panels;
    },
  },
});
