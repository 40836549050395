import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';

export interface StorageServiceInterface {
  getItem(key: string): Promise<string | null>;

  setItem(key: string, value: string): void;

  removeItem(key: string): void;

  clear(): void;
}

const createBrowserLocaleStorageService = (): StorageServiceInterface => {
  return {
    async getItem(key) {
      return localStorage.getItem(key);
    },
    setItem(key, value) {
      localStorage.setItem(key, value);
    },
    clear() {
      localStorage.clear();
    },
    removeItem(key) {
      localStorage.removeItem(key);
    },
  };
};

const createAppStorageService = (): StorageServiceInterface => {
  return {
    async getItem(key) {
      const { value } = await Preferences.get({ key });
      return value;
    },
    async setItem(key, value) {
      await Preferences.set({ key, value });
    },
    async clear() {
      await Preferences.clear();
    },
    async removeItem(key) {
      await Preferences.remove({ key });
    },
  };
};

const createService = (): StorageServiceInterface => {
  if (Capacitor.isNativePlatform()) {
    return createAppStorageService();
  } else {
    return createBrowserLocaleStorageService();
  }
};

const storageService = createService();

export { storageService };
