import { defineStore } from 'pinia';
import {
  ApiError,
  ClaimInstructionsResponse,
  ClientErrorInterface,
} from '@ambassy/schema';

export const useRewardClaimStore = defineStore('rewardClaimStore', {
  state: () => ({
    isLoading: true,
    errors: [] as ApiError<ClaimInstructionsResponse['fields']>,
    claimInfo: {} as Omit<ClaimInstructionsResponse, 'fields'> & {
      fields: ClaimInstructionsResponse['fields'] & { value: string | null }[];
    },
  }),
  actions: {
    startLoading() {
      this.isLoading = true;
    },
    stopLoading() {
      this.isLoading = false;
    },
    clearError() {
      this.errors = [];
    },
    setError(err: ClientErrorInterface<ClaimInstructionsResponse['fields']>) {
      this.errors = err.getErrors();
    },
    setClaimInfo(claimInfo: ClaimInstructionsResponse) {
      this.claimInfo = {
        ...claimInfo,
        fields: claimInfo.fields.map((field) => ({ ...field, value: null })),
      };
    },
  },
});
