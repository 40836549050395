import { defineStore } from 'pinia';
import { ClientErrorInterface, QuestDiscountResponse } from '@ambassy/schema';

export const useDiscountStore = defineStore('discounts', {
  state: () => ({
    discounts: {} as QuestDiscountResponse,
    isLoading: true,
    errorMessage: '',
  }),
  actions: {
    setDiscounts(discounts: QuestDiscountResponse) {
      this.discounts = discounts;
    },
    setError(err: ClientErrorInterface<any>) {
      this.errorMessage = err.message;
    },
    stopLoading() {
      this.isLoading = false;
    },
    startLoading() {
      this.isLoading = true;
    },
  },
});
