<template>
  <Dialog
    v-if="dialog"
    :open="open"
    @update:open="open = $event"
    :dialog="dialog"
  />
</template>

<script setup lang="ts">
import Dialog from './Dialog.vue';
import { DialogContainerInterface, DialogInterface } from './dialog';
import { ref } from 'vue';

const dialog = ref<DialogInterface>();
const open = ref(false);

const showDialog = (d: DialogInterface) => {
  open.value = true;
  dialog.value = d;
};
defineExpose<DialogContainerInterface>({
  showDialog,
});
</script>
