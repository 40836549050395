import { defineStore } from 'pinia';
import {
  ClientErrorInterface,
  AmbassadorQuestTaskResponse,
  ImageUploadResponse,
} from '@ambassy/schema';
import { AxiosProgressEvent } from 'axios';

export const useTasksStore = defineStore('quest-tasks-store', {
  state: () => ({
    tasks: [] as AmbassadorQuestTaskResponse,
    submit: undefined as
      | ((
          taskId: string,
          resource: string | File | string[],
        ) => Promise<unknown>)
      | undefined,
    uploadingState: {} as {
      [key: string]: 'DEFAULT' | 'LOADING' | 'SUCCESS' | 'ERROR';
    },
    resources: {} as { [key: string]: string | File },
    errors: {} as { [key: string]: string },
    isLoading: true,
    errorMessage: '',
  }),
  getters: {
    getTaskStatus: (state) => (taskId: string) => {
      return (
        state.tasks.find((task) => task.id === taskId)?.status ?? undefined
      );
    },
    getUploadingState: (state) => (taskId: string) => {
      return state.uploadingState[taskId] ?? 'DEFAULT';
    },
  },
  actions: {
    setTasks(tasks: AmbassadorQuestTaskResponse) {
      this.tasks = tasks;
    },
    setResource(taskId: string, resource: string | File) {
      this.resources[taskId] = resource;
    },
    setTaskState(
      taskId: string,
      state: AmbassadorQuestTaskResponse[number]['status'],
    ) {
      const task = this.tasks.find((task) => task.id === taskId);
      if (task) {
        task.status = state;
      }
    },
    setUploadingState(
      taskId: string,
      state: 'DEFAULT' | 'LOADING' | 'SUCCESS' | 'ERROR',
    ) {
      this.uploadingState[taskId] = state;
    },
    setSubmitter(
      submit: (
        taskId: string,
        resource: string | File | string[],
      ) => Promise<unknown>,
    ) {
      this.submit = submit;
    },
    setError(err: ClientErrorInterface<any>) {
      this.errorMessage = err.message;
    },
    setTaskError(taskId: string, error: string) {
      this.errors[taskId] = error;
    },
    stopLoading() {
      this.isLoading = false;
    },
    startLoading() {
      this.isLoading = true;
    },
  },
});
