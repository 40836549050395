import { inject, provide, Ref } from 'vue';

export const DialogContainerContextId = Symbol('dialog-container');

export interface DialogInterface {
  title: string;
  message: string;
  inputLabel?: string;
  inputPlaceholder?: string;
  inputType?: 'text';
  type: 'error' | 'warning' | 'info' | 'none';
  closeButton: string;
  confirmButton: string;
  onClose?: (context: { input: string | null }) => void;
  onConfirm?: (context: { input: string | null }) => void;
}
export interface DialogContainerInterface {
  showDialog: (dialog: DialogInterface) => void;
}

export const registerDialogContainer = (
  container: Ref<DialogContainerInterface | undefined>,
) => {
  provide(DialogContainerContextId, container);
};

const useDialogContainer = ():
  | Ref<DialogContainerInterface | undefined>
  | undefined => {
  return inject(DialogContainerContextId);
};

export const useDialog = () => {
  const dialogContainer = useDialogContainer();
  return {
    showDialog: (dialog: DialogInterface) =>
      dialogContainer?.value?.showDialog(dialog),
  };
};
