import { ref } from 'vue';
import { QuestsPageTextResponse } from '@ambassy/schema';

const questsPage = ref<QuestsPageTextResponse>();

export const setQuestsPageText = (text: QuestsPageTextResponse) => {
  questsPage.value = text;
};

export const useQuestsPageText = () => {
  return questsPage;
};
