<template>
  <router-view />
  <ToastContainer ref="toastContainer" />
  <DialogContainer ref="dialogContainer" />
</template>
<script setup lang="ts">
import ToastContainer from './Kernel/Components/Molecules/Toast/ToastContainer.vue';
import { ref } from 'vue';
import {
  registerToastContainer,
  type ToastContainerInterface,
  DialogContainer,
  DialogContainerInterface,
  registerDialogContainer,
} from '@ambassy/innercrowd-ui';

const toastContainer = ref<ToastContainerInterface>();
registerToastContainer(toastContainer);

const dialogContainer = ref<DialogContainerInterface>();
registerDialogContainer(dialogContainer);
</script>
