import { defineStore } from 'pinia';
import {
  AmbassadorAccountStatsResponse,
  ClientErrorInterface,
} from '@ambassy/schema';

export const useAmbassadorStatsStore = defineStore('ambassador-stats', {
  state: () => ({
    isLoading: true,
    errorMessage: '',
    stats: {} as AmbassadorAccountStatsResponse,
  }),
  actions: {
    startLoading() {
      this.isLoading = true;
    },
    stopLoading() {
      this.isLoading = false;
    },
    clearError() {
      this.errorMessage = '';
    },
    setError(error: ClientErrorInterface<any>) {
      this.errorMessage = error.message;
    },
    setStats(stats: AmbassadorAccountStatsResponse | null) {
      this.stats = stats || {
        productsSold: 0,
        points: 0,
        tasksCompleted: 0,
        rewardsClaimed: 0,
      };
    },
  },
});
