import { type Ref, ref } from 'vue';
import { colord } from 'colord';

const addColorBlendToTheme = (theme: BaseThemeObject): Theme => {
  const grades = [0.05, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1];
  const primaryColorBled = grades.reduce(
    (acc, p) => {
      acc[p * 1000] = colord(theme.primaryColor).alpha(p).toHex();
      return acc;
    },
    {} as Record<string, string>,
  );
  const secondaryColorBled = grades.reduce(
    (acc, p) => {
      acc[p * 1000] = colord(theme.secondaryColor).alpha(p).toHex();
      return acc;
    },
    {} as Record<string, string>,
  );
  return {
    ...theme,
    primaryColorBled,
    secondaryColorBled,
  };
};

type BaseThemeObject = {
  primaryColor: string;
  secondaryColor: string;
  backgroundColor: string;
  textTitleColor: string;
  primaryButtonColor: string;
  primaryButtonTextColor: string;
  secondaryButtonColor: string;
  secondaryButtonTextColor: string;
  logo: string;
  organisationName: string;
  appLanguage: string;
  phoneCode: string;
};

export type Theme = {
  primaryColorBled: { [key: string]: string };
  secondaryColorBled: { [key: string]: string };
  primaryColor: string;
  secondaryColor: string;
  backgroundColor: string;
  textTitleColor: string;
  primaryButtonColor: string;
  primaryButtonTextColor: string;
  secondaryButtonColor: string;
  secondaryButtonTextColor: string;
  logo: string;
  organisationName: string;
  appLanguage: string;
  phoneCode: string;
};

const defaultTheme: Theme = {
  primaryColor: '#101828',
  secondaryColor: '#FFFFFF',
  backgroundColor: '#D1C4FF',
  textTitleColor: '#000000',
  primaryButtonColor: '#5E2BFF',
  primaryButtonTextColor: '#FFFFFF',
  secondaryButtonColor: '#FFFFFF',
  secondaryButtonTextColor: '#101828',
  logo: '',
  organisationName: '',
  primaryColorBled: {},
  secondaryColorBled: {},
  appLanguage: 'en',
  phoneCode: 'NL',
};

const applicationTheme = ref<Theme>(addColorBlendToTheme(defaultTheme));

export const registerTheme = (theme: BaseThemeObject): void => {
  applicationTheme.value = addColorBlendToTheme(theme);
};

export const useTheme = (): Ref<Theme> => {
  return applicationTheme;
};

export const registerDefaultTheme = () => {
  registerTheme(defaultTheme);
};
