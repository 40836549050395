import { defineStore } from 'pinia';
import {
  ClientErrorInterface,
  QuestsAmbassadorsResponse,
} from '@ambassy/schema';

export const useQuestSocialProofStore = defineStore('social-proof-store', {
  state: () => ({
    socialProof: [] as QuestsAmbassadorsResponse,
    isLoading: true,
    errorMessage: '',
  }),
  actions: {
    setSocialProof(socialProof: QuestsAmbassadorsResponse) {
      this.socialProof = socialProof;
    },
    stopLoading() {
      this.isLoading = false;
    },
    startLoading() {
      this.isLoading = true;
    },
    setError(err: ClientErrorInterface<any>) {
      this.errorMessage = err.message;
    },
  },
  getters: {
    findByQuestId:
      (
        state,
      ): ((questId: string) => QuestsAmbassadorsResponse[number] | undefined) =>
      (questId: string) => {
        return state.socialProof.find((proof) => proof.questId === questId);
      },
  },
});
