import { NavigationGuardWithThis } from 'vue-router';
import { authenticationService } from './index';

export const dashboardPermissionsGuard: NavigationGuardWithThis<
  unknown
> = async (to, _from, next) => {
  try {
    const hasPermissions =
      await authenticationService.checkDashboardPermissions();
    if (hasPermissions.authorized) {
      next();

      return;
    }
  } catch (e) {
    authenticationService.redirectToLogin(to.fullPath);
    return;
  }

  authenticationService.redirectToLogin(to.fullPath);
};
