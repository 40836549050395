<script setup lang="ts">
const emits = defineEmits<{
  (events: 'text-button-clicked'): void;
}>();
const props = defineProps<{
  isLoading?: boolean;
  disabled?: boolean;
}>();

const textButtonClicked = () => {
  if (!props.disabled) {
    emits('text-button-clicked');
  }
};
</script>

<template>
  <button
    @click="textButtonClicked"
    type="button"
    :class="[
      isLoading ? 'cursor-wait' : 'cursor-pointer',
      'text-sm font-medium',
    ]"
    role="button"
  >
    <slot />
  </button>
</template>
