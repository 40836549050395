import { defineStore } from 'pinia';
import {
  AmbassadorQuestContentResponse,
  ClientErrorInterface,
  UploadLinkResponse,
} from '@ambassy/schema';
import { AxiosProgressEvent } from 'axios';

export const useQuestUserGeneratedContentStore = defineStore(
  'quest-user-generated-content',
  {
    state: () => ({
      content: {
        status: null,
      } as AmbassadorQuestContentResponse,
      files: [] as {
        file: File;
        isUploading: boolean;
        error: string | null;
        url: string;
      }[],
      submitter: undefined as ((files: string[]) => Promise<void>) | undefined,
      generateUploadUrl: undefined as
        | ((file: File) => Promise<UploadLinkResponse>)
        | undefined,
      putUploadFile: undefined as
        | ((file: File, url: string) => Promise<void>)
        | undefined,
      isLoading: true,
      errorMessage: null as string | null,
      uploadedProgress: [] as { file: File; progress: number }[],
    }),
    actions: {
      progress(file: File, progressEvent: ProgressEvent) {
        const index = this.uploadedProgress.findIndex((p) => p.file === file);
        if (index !== -1) {
          this.uploadedProgress[index].progress = progressEvent.loaded;
        }
      },
      addContent(file: File) {
        this.files.push({ file, isUploading: true, error: null, url: '' });
        this.uploadedProgress.push({ file, progress: 0 });
      },
      removeContent(file: File) {
        this.files = this.files.filter((c) => c.file !== file);
      },
      setIsInReview() {
        this.content.status = 'PENDING';
      },
      startLoading() {
        this.isLoading = true;
      },
      stopLoading() {
        this.isLoading = false;
      },
      setApiError(err: ClientErrorInterface<any>) {
        this.errorMessage = err.message;
      },
      setConnectionError(err: Error) {
        console.error(err);
        this.errorMessage = err.message;
      },
      resetErrors() {
        this.errorMessage = null;
      },
      setError(file: File, error: string) {
        const index = this.files.findIndex((c) => c.file === file);
        if (index !== -1) {
          this.files[index].error = error;
          this.files[index].isUploading = false;
        }
      },
      setContent(content: AmbassadorQuestContentResponse) {
        this.content = content;
      },
      addResource(url: string, file: File) {
        const index = this.files.findIndex((c) => c.file === file);
        if (index !== -1) {
          this.files[index].url = url;
        }
      },
      setSubmitter(submitter: (files: string[]) => Promise<void>) {
        this.submitter = submitter;
      },
      setGenerateUploadUrl(
        generateUploadUrl: (file: File) => Promise<UploadLinkResponse>,
      ) {
        this.generateUploadUrl = generateUploadUrl;
      },
      setPutUploadFile(
        putUploadFile: (file: File, url: string) => Promise<void>,
      ) {
        this.putUploadFile = putUploadFile;
      },
      markFileAsUploaded(file: File) {
        const index = this.files.findIndex((c) => c.file === file);
        if (index !== -1) {
          this.files[index].isUploading = false;
        }
      },
    },
    getters: {
      fileProgress: (state) => (file: File) => {
        const progress = state.uploadedProgress.find((p) => p.file === file);
        return progress ? progress.progress : 0;
      },
      allFilesUploaded: (state) => {
        return state.files.every((f) => !f.isUploading);
      },
    },
  },
);
