import { User } from '@ambassy/api-client';

export const updateIntercom = () => {
  if (import.meta.env.VITE_ENV === 'production') {
    window.Intercom('update');
  }
};

export const initIntercom = (user: User) => {
  if (import.meta.env.VITE_ENV === 'production') {
    window.Intercom('boot', {
      api_base: 'https://api-iam.intercom.io',
      app_id: 'mcmp5qlg',
      name: user.name,
      email: user.email,
      created_at: null,
    });
  }
};
