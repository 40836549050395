import { defineStore } from 'pinia';
import {
  ApiError,
  ClientErrorInterface,
  SubmitAmbassadorOptInRequest,
} from '@ambassy/schema';

export const useRegisterForOrganisationStore = defineStore(
  'registerForOrganisation',
  {
    state: () => ({
      fields: {} as SubmitAmbassadorOptInRequest,
      isSubmitting: false,
      errors: {} as ApiError<SubmitAmbassadorOptInRequest>,
      errorMessage: null as string | null,
    }),
    actions: {
      setField<K extends keyof SubmitAmbassadorOptInRequest>(
        field: K,
        value: SubmitAmbassadorOptInRequest[K] | null,
      ) {
        this.fields[field] = value;
      },
      startSubmitting() {
        this.isSubmitting = true;
      },
      clearErrors() {
        this.errors = {};
        this.errorMessage = null;
      },
      stopSubmitting() {
        this.isSubmitting = false;
      },
      setErrors(errors: ClientErrorInterface<SubmitAmbassadorOptInRequest>) {
        this.errors = errors.getErrors();
        this.errorMessage = errors.message;
      },
    },
    getters: {
      getField: (state) => (fieldSlug: string) => {
        return state.fields[fieldSlug];
      },
      getError: (state) => (fieldSlug: string) => {
        return state.errors[fieldSlug];
      },
    },
  },
);
