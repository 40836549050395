import { defineStore } from 'pinia';
import {
  AmbassadorQuestsDetailResponse,
  ClientErrorInterface,
} from '@ambassy/schema';

export const useQuestDetailStore = defineStore('quest-detail', {
  state: () => ({
    quest: {} as AmbassadorQuestsDetailResponse,
    isLoading: true,
    error: '',
  }),
  actions: {
    setQuest(quest: AmbassadorQuestsDetailResponse) {
      this.quest = quest;
    },
    stopLoading() {
      this.isLoading = false;
    },
    startLoading() {
      this.isLoading = true;
    },
    setError(err: ClientErrorInterface<any>) {
      this.error = err.message;
    },
    clearError() {
      this.error = '';
    },
  },
});
