import { createI18n } from 'vue-i18n';
import { nextTick } from 'vue';
import defaultMessages from './i18n/en.json';

export const defaultLocale = 'en';

let i18n: ReturnType<typeof createI18n>;

function setupI18n(options = { locale: defaultLocale }) {
  i18n = createI18n({
    legacy: false,
    locale: options.locale,
    fallbackLocale: 'en',
    messages: { [defaultLocale]: defaultMessages },
  });
}

function setLocale(locale: string) {
  // @ts-ignore
  i18n.global.locale.value = locale;
}

function setLanguage(locale: string) {
  document.querySelector('html')?.setAttribute('lang', locale);
}

async function loadMessagesFor(locale: string) {
  const messages = await import(`./i18n/${locale}.json`);
  i18n.global.setLocaleMessage(locale, messages.default);
  return nextTick();
}

export default {
  get vueI18n() {
    return i18n;
  },
  setupI18n,
  setLocale,
  setLanguage,
  loadMessagesFor,
};
