import { NavigationGuardWithThis } from 'vue-router';
import { authenticationService } from './index';
import { apiClient } from '../client/apiClient';

export const loggedInUserGuard: NavigationGuardWithThis<unknown> = async (
  to,
  _from,
  next,
) => {
  if (await authenticationService.isLegacyToken()) {
    await authenticationService.exchangeLegacyToken();
  }

  if (await authenticationService.isLoggedIn()) {
    apiClient.authorize(await authenticationService.getToken());
    next();

    return;
  }

  authenticationService.redirectToLogin(to.fullPath);
};
