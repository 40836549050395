export default class ClientError<T> extends Error {
  private readonly errorMessages: { [key in keyof T]: string | string[] };

  constructor(
    error: string,
    messages: { [key in keyof T]: string | string[] },
    public readonly status: number,
  ) {
    super(error);
    this.errorMessages = messages;
  }

  getErrors(): { [key in keyof T]: string | string[] } {
    return this.errorMessages ?? {};
  }

  getCode(): number {
    return this.status;
  }
}
