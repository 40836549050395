import { defineStore } from 'pinia';
import { ClientErrorInterface, RewardsForQuestResponse } from '@ambassy/schema';

export const useQuestPointsStore = defineStore('points-store', {
  state: () => ({
    points: [] as RewardsForQuestResponse,
    isLoading: true,
    errorMessage: '',
  }),
  actions: {
    setPoints(points: RewardsForQuestResponse) {
      this.points = points;
    },
    stopLoading() {
      this.isLoading = false;
    },
    startLoading() {
      this.isLoading = true;
    },
    setError(err: ClientErrorInterface<any>) {
      this.errorMessage = err.message;
    },
  },
  getters: {
    findAllByQuestId: (state) => (questId: string) => {
      return state.points.filter((point) => point.questId === questId);
    },
    findPointsByQuestId:
      (state) =>
      (questId: string): number[] => {
        return state.points
          .filter((point) => point.questId === questId)
          .map((point) => point.points ?? 0);
      },
    showPoints:
      (state) =>
      (questId: string): string | null => {
        const points = state.points.filter(
          (point) => point.questId === questId,
        );

        if (points.length < 1) {
          return null;
        }
        if (points.length === 1) {
          return points[0].points?.toString() ?? '';
        }

        const minPoints = points.reduce(
          (min, p) => (p.points < min ? p.points : min),
          points[0].points,
        );
        const sumPoints = points.reduce((sum, p) => sum + p.points, 0);

        return `${minPoints}-${sumPoints}`;
      },
  },
});
