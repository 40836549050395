import { defineStore } from 'pinia';
import {
  AmbassadorTicketQuestPerformanceResponse,
  AmbassadorTicketQuestShareLinkResponse,
  ClientErrorInterface,
  TicketQuestProductsResponse,
} from '@ambassy/schema';

export const useTicketQuestStore = defineStore('ticketQuestStore', {
  state: () => ({
    shareLink: {} as AmbassadorTicketQuestShareLinkResponse,
    performance: {} as AmbassadorTicketQuestPerformanceResponse,
    tickets: [] as TicketQuestProductsResponse,
    isLoading: false,
    errorMessage: '',
  }),
  actions: {
    startLoading() {
      this.isLoading = true;
    },
    stopLoading() {
      this.isLoading = false;
    },
    setError(err: ClientErrorInterface<any>) {
      this.errorMessage = err.message;
    },
    setShareLink(shareLink: AmbassadorTicketQuestShareLinkResponse) {
      this.shareLink = shareLink;
    },
    setPerformance(performance: AmbassadorTicketQuestPerformanceResponse) {
      this.performance = performance;
    },
    setTickets(tickets: TicketQuestProductsResponse) {
      this.tickets = tickets;
    },
  },
});
