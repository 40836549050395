<template>
  <TransitionRoot
    :show="show"
    as="template"
    enter="transition-all duration-150"
    enter-from="opacity-0 translate-y-8"
    enter-to="opacity-100 translate-y-0"
    leave="transition-all duration-300"
    leave-from="opacity-100 translate-x-0"
    leave-to="opacity-0 translate-x-16"
  >
    <div
      class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
      role="alert"
      @mouseover="preventClose"
      @mouseleave="releaseCloseLock"
    >
      <div class="p-4">
        <div class="flex items-start">
          <div class="flex-shrink-0">
            <CheckCircleIcon
              v-if="toast.type === 'success'"
              class="h-6 w-6 text-green-400"
              aria-hidden="true"
            />
            <ExclamationCircleIcon
              v-if="toast.type === 'error'"
              class="h-6 w-6 text-red-400"
              aria-hidden="true"
            />
            <InformationCircleIcon
              v-if="toast.type === 'info'"
              class="h-6 w-6 text-blue-400"
              aria-hidden="true"
            />
          </div>
          <div class="ml-3 w-0 flex-1 pt-0.5">
            <p class="text-sm font-medium text-gray-900">{{ toast.title }}</p>
            <p v-if="toast.message" class="mt-1 text-sm text-gray-500">
              {{ toast.message }}
            </p>
            <div class="mt-4 flex items-center" v-if="toast.actions">
              <template v-for="(action, index) in toast.actions" :key="index">
                <span
                  v-if="index > 0"
                  class="inline-flex items-center mx-2 w-1 h-1 rounded-full text-sm font-medium bg-indigo-600 text-gray-800"
                >
                  &nbsp;
                </span>
                <TextButton
                  class="text-indigo-600 hover:text-indigo-500"
                  @text-button-clicked="doAction(action)"
                >
                  {{ action.label }}
                </TextButton>
              </template>
            </div>
          </div>
          <div class="ml-4 flex flex-shrink-0">
            <button
              type="button"
              @click="forceClose"
              class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <span class="sr-only">Close</span>
              <XMarkIcon class="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
} from '@heroicons/vue/24/outline';
import { XMarkIcon } from '@heroicons/vue/20/solid';
import { ToastInterface } from './toast';
import TextButton from '../../atoms/Buttons/TextButton.vue';
import { TransitionRoot } from '@headlessui/vue';

const props = defineProps<{
  toast: ToastInterface;
}>();

const show = ref(false);
const closeLock = ref(false);
const needsClosing = ref(false);
onMounted(() => {
  show.value = true;
});

const doAction = (action: { onClick: () => void }) => {
  action.onClick();
  show.value = false;
};

const close = () => {
  needsClosing.value = true;
  if (closeLock.value) {
    return;
  }
  show.value = false;
  props.toast.onClose(props.toast.id);
};

const preventClose = () => {
  closeLock.value = true;
};

const releaseCloseLock = () => {
  closeLock.value = false;
  if (needsClosing.value) {
    close();
  }
};

const forceClose = () => {
  closeLock.value = false;
  close();
};

defineExpose({
  id: props.toast.id,
  close,
});
</script>
