import { defineStore } from 'pinia';
import {
  AmbassadorQuestsResponse,
  AmbassadorQuestStatusResponse,
  ClientErrorInterface,
  QuestsAmbassadorsResponse,
  RewardsForQuestResponse,
} from '@ambassy/schema';

export const useQuestsStore = defineStore('quests', {
  state: () => ({
    quests: {} as AmbassadorQuestsResponse,
    ambassadors: [] as QuestsAmbassadorsResponse,
    rewards: [] as RewardsForQuestResponse,
    status: {} as AmbassadorQuestStatusResponse,
    isLoading: true,
    errorMessage: '',
  }),
  actions: {
    setQuests(quests: AmbassadorQuestsResponse) {
      this.quests = quests;
    },
    setAmbassadors(ambassadors: QuestsAmbassadorsResponse) {
      this.ambassadors = ambassadors;
    },
    setRewards(rewards: RewardsForQuestResponse) {
      this.rewards = rewards;
    },
    setStatus(status: AmbassadorQuestStatusResponse) {
      this.status = status;
    },
    setError(err: ClientErrorInterface<any>) {
      this.errorMessage = err.message;
    },
    stopLoading() {
      this.isLoading = false;
    },
    startLoading() {
      this.isLoading = true;
    },
  },
  getters: {
    findAmbassadorsByQuestId: (state) => (questId: string) => {
      return state.ambassadors.find(
        (ambassador) => ambassador.questId === questId,
      );
    },
    findRewardsByQuestId: (state) => (questId: string) => {
      return state.rewards.filter((reward) => reward.questId === questId);
    },
    findStatusByQuestId: (state) => (questId: string) => {
      return state.status.find((status) => status.questId === questId);
    },
  },
});
