import { inject, provide, type Ref } from 'vue';

export const ToastContainerContextId = Symbol('toast-container');
export const registerToastContainer = (
  container: Ref<ToastContainerInterface | undefined>,
) => {
  provide(ToastContainerContextId, container);
};

const useToastContainer = ():
  | Ref<ToastContainerInterface | undefined>
  | undefined => {
  return inject(ToastContainerContextId);
};

export interface ToastInterface {
  id: number;
  title: string;
  message?: string;
  type: 'success' | 'error' | 'warning' | 'info';
  duration: number;
  actions?: ToastAction[];
  onClose: (id: number) => void;
}
export interface ToastContainerInterface {
  addToast: (toast: Omit<ToastInterface, 'id'>) => void;
  removeToast: (id: number) => void;
  removeAllToasts: () => void;
}
export type ToastAction = {
  label: string;
  onClick: () => void;
};
export const useToast = () => {
  const dialogContainer = useToastContainer();
  const defaultToastDuration = 10000;
  return {
    success: (title: string, message?: string, actions?: ToastAction[]) =>
      dialogContainer?.value?.addToast({
        title,
        message,
        type: 'success',
        duration: defaultToastDuration,
        actions,
        onClose: dialogContainer?.value?.removeToast,
      }),
    error: (title: string, message?: string, actions?: ToastAction[]) =>
      dialogContainer?.value?.addToast({
        title,
        message,
        type: 'error',
        duration: defaultToastDuration,
        actions,
        onClose: dialogContainer?.value?.removeToast,
      }),
    warning: (title: string, message?: string, actions?: ToastAction[]) =>
      dialogContainer?.value?.addToast({
        title,
        message,
        type: 'warning',
        duration: defaultToastDuration,
        actions,
        onClose: dialogContainer?.value?.removeToast,
      }),
    info: (title: string, message?: string, actions?: ToastAction[]) =>
      dialogContainer?.value?.addToast({
        title,
        message,
        type: 'info',
        duration: defaultToastDuration,
        actions,
        onClose: dialogContainer?.value?.removeToast,
      }),
  };
};
